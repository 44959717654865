import React, { useContext, useState, useEffect } from "react"
import styled from "styled-components"

import FlexSection from "../reusable-components/layout/flex-section"
import LoadingSubmitButton from "../reusable-components/buttons/loading-submit-button"
import SubmitButton from "../reusable-components/buttons/submit-button"
import CompactLoader from "../reusable-components/loaders/compact-loader"
import WishlistIcon from "../../images/icons/wishlist"
import Counter from "./counter"

import AreaCalcContext from "../../context/area-calc/area-calc-context"
import CartContext from "../../context/cart/cart-context"
import { navigate } from "gatsby"

import { roundTo2 } from "../../lib/utils"

const ProductQuantityRow = ({ productId, selectedSquareMPerBox }) => {
  const { openAreaCalcPopup } = useContext(AreaCalcContext)
  const {
    cartReady,
    addToCart,
    openCartDrawer,
    addToWishlist,
    removeFromWishlist,
    wishlistContents,
  } = useContext(CartContext)

  // This state is used to keep track of the quantity
  const [quantity, setQuantity] = useState(1)
  // This forces the Counter component to update it's value
  const [quantityForceSetComponentValue, setQuantityForceSetComponentValue] =
    useState(1)
  // This forces the Counter component to update it's value
  const [squareMForceSetComponentValue, setSquareMForceSetComponentValue] =
    useState(selectedSquareMPerBox)
  const [addToCartLoading, setAddToCartLoading] = useState(false)
  const [apiError, setApiError] = useState("")

  useEffect(() => {
    setQuantity(1)
    setQuantityForceSetComponentValue(1)
    setSquareMForceSetComponentValue(selectedSquareMPerBox)
  }, [selectedSquareMPerBox])

  const handleAddToCart = () => {
    setAddToCartLoading(true)
    addToCart({ id: productId, quantity })
      .then(() => {
        setApiError("")
        setAddToCartLoading(false)
        openCartDrawer(true)
      })
      .catch(async (error) => {
        let errorString = "Failed to add product to cart"
        if (error.message) {
          errorString = error.message
          console.log("Error Response: ", errorString)
        } else {
          console.log("Error Response: ", error)
        }
        setApiError(errorString)
        setAddToCartLoading(false)
      })
  }

  // Convenience variable to chech if current item is wishlisted
  const isWishlisted =
    wishlistContents &&
    wishlistContents.length &&
    wishlistContents.some((item) => item.product_id == productId)

  // Handle clicking on the wishlist button, either adding or removing from wishlist
  const handleWishlistButton = () => {
    // If on of these calls fail, the user is probably not logged in
    if (isWishlisted) {
      removeFromWishlist(productId)
    } else {
      addToWishlist(productId).catch((err) => {
        if (
          err &&
          err.message &&
          err.message.includes("need to be logged in")
        ) {
          navigate("/login/", {
            state: {
              fromUrl: window.location.pathname,
              showNotice: "You need to be logged in to use your wishlist",
            },
          })
        }
      })
    }
  }

  const updateSquareM = (newSquareM) => {
    const newBoxes = Math.ceil(newSquareM / selectedSquareMPerBox)
    if (!newBoxes) {
      // This state is used to keep track of the quantity
      setQuantity(1)
      // This forces the Counter component to update it's value
      setQuantityForceSetComponentValue(1)
    } else if (!isNaN(newBoxes) && newBoxes != quantity) {
      // This state is used to keep track of the quantity
      setQuantity(newBoxes)
      // This forces the Counter component to update it's value
      setQuantityForceSetComponentValue(newBoxes)
    }
  }

  const updateQuantity = (newQuantity) => {
    const newSquareM = roundTo2(newQuantity * selectedSquareMPerBox)
    if (!newSquareM) {
      // This state is used to keep track of the quantity
      setQuantity(1)
      // This forces the Counter component to update it's value
      setSquareMForceSetComponentValue(selectedSquareMPerBox)
    } else if (
      !isNaN(newSquareM) &&
      newSquareM != squareMForceSetComponentValue
    ) {
      // This state is used to keep track of the quantity
      setQuantity(newQuantity)
      // This forces the Counter component to update it's value
      setSquareMForceSetComponentValue(newSquareM)
    }
  }

  return (
    <>
      {!selectedSquareMPerBox ? null : (
        <FlexSectionStyling direction="row" margin="0 0 1rem">
          <StyledOpenCalcButton
            className="overwrite-width"
            onClick={() => {
              openAreaCalcPopup(productId, selectedSquareMPerBox)
            }}
          >
            Calculate how many boxes you need
          </StyledOpenCalcButton>
          <SquareMQuantitySectionStyling direction="row">
            <SqareMQuantitySectionColumnStyling>
              <div>
                {`Boxes (${selectedSquareMPerBox} m`}
                <sup>2</sup>
                {")"}
              </div>
              <Counter
                value={quantityForceSetComponentValue}
                onChange={updateQuantity}
                stepSize={1}
                min={1}
              />
            </SqareMQuantitySectionColumnStyling>
            <SqareMQuantitySectionColumnStyling>
              <div>
                or enter your required m<sup>2</sup>
              </div>
              <Counter
                value={squareMForceSetComponentValue}
                onChange={updateSquareM}
              />
            </SqareMQuantitySectionColumnStyling>
          </SquareMQuantitySectionStyling>
        </FlexSectionStyling>
      )}
      <FlexSectionStyling direction="row" margin="0 0 1rem">
        {selectedSquareMPerBox ? null : (
          <StandardQuantitySection direction="row">
            Quantity
            <Counter min={1} stepSize={1} onChange={setQuantity} />
          </StandardQuantitySection>
        )}
        <StyledLoadingSubmitButton
          className="overwrite-width"
          text={
            !selectedSquareMPerBox
              ? "Add to cart"
              : `Add ${quantity} box${
                  quantity > 1 ? "es" : ""
                } (${squareMForceSetComponentValue} m2) to Cart`
          }
          loading={addToCartLoading}
          loaderColour="white"
          callbackFunction={handleAddToCart}
          loaderHeight="19px"
          padding="0"
        />
        <WishlistLinkStyling
          onClick={handleWishlistButton}
          active={isWishlisted}
        >
          <WishlistButtonGridStyling>
            {cartReady ? (
              <>
                <WishlistIcon />
                <WishListButtonStyling
                  text={
                    isWishlisted ? "Remove from wishlist" : "Add to wishlist"
                  }
                />
              </>
            ) : (
              <CompactLoader height="51px" />
            )}
          </WishlistButtonGridStyling>
        </WishlistLinkStyling>
        {!apiError ? null : (
          <ErrorMessage dangerouslySetInnerHTML={{ __html: apiError }} />
        )}
      </FlexSectionStyling>
    </>
  )
}

export default ProductQuantityRow

// ===============
//     STYLES
// ===============

const StyledLoadingSubmitButton = styled(LoadingSubmitButton)`
  &.overwrite-width {
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      width: 50%;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      width: 100%;
    }
  }
`

const StyledOpenCalcButton = styled.button`
  &.overwrite-width {
    margin-bottom: 1.5rem;
    width: 100%;
  }
`

const ErrorMessage = styled.div`
  width: 100%;
  padding-top: 5px;
  color: ${({ theme }) => theme.colors.danger.dark};
`

const FlexSectionStyling = styled(FlexSection)`
  flex-wrap: wrap;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    flex-direction: row;
    flex-wrap: wrap;
    > a {
      width: fit-content !important;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin-bottom: 0;
    button {
      width: fit-content;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    flex-direction: column;
    .wishlist {
      padding-left: 0;
    }
  }
`
const SqareMQuantitySectionColumnStyling = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    width: 100%;
  }
`
const SquareMQuantitySectionStyling = styled(FlexSection)`
  margin: 0;
  width: 100%;
  line-height: 53px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
    flex-wrap: no-wrap;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-bottom: 0.313rem;
  }
`
const StandardQuantitySection = styled(FlexSection)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin: 0;
  width: fit-content;
  line-height: 53px;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
    flex-wrap: no-wrap;
    width: 100%;
    justify-content: center;
    margin: auto;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-bottom: 0.313rem;
  }
`
const WishlistLinkStyling = styled.div`
  padding-left: 1.25rem;
  svg {
    path {
      stroke: ${({ theme }) => theme.colors.blue};
      ${({ theme, active }) => active && `fill: ${theme.colors.blue}`};
    }
  }
  &:hover svg {
    path {
      fill: ${({ theme }) => theme.colors.blue};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    padding-left: 0;
  }

  margin: auto;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
  }
`
const WishlistButtonGridStyling = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  > a:first-child {
    height: 30px;
  }
`
const WishListButtonStyling = styled(SubmitButton)`
  padding: 1rem 0 1rem 0.75rem !important;
  border: none !important;
  background: white;
  &:hover,
  &.button-active {
    background-color: white !important;
    color: ${({ theme }) => theme.colors.blue} !important;
  }
`
