import React from "react"
import styled from "styled-components"

import ProductList from "../../components/products/product-list"

const ProductRelatedSection = ({ relatedProducts }) => {
  return (
    <RelatedSectionContainer>
      <div>
        <h4>Related Products</h4>
        <StyledProductList
          products={relatedProducts}
          columnNumber={5}
          listId="related_products"
        />
      </div>
    </RelatedSectionContainer>
  )
}

export default ProductRelatedSection

// ===============
//     STYLES
// ===============
const RelatedSectionContainer = styled.div`
  > div {
    margin: 0 1.875rem;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      margin: 0;
    }
  }
  h4 {
    margin-bottom: 3.125rem;
  }
`
const StyledProductList = styled(ProductList)`
  > div {
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      width: calc(calc(100% - calc(10px * 1)) / 3);
      :not(
          :nth-child(
              ${({ columnNumber }) => columnNumber - 1}n
                + ${({ columnNumber }) => columnNumber - 1}
            )
        ) {
        margin-right: 0;
      }
      :not(:nth-child(3n + 3)) {
        margin-right: 5px;
      }
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      width: calc(calc(100% - calc(10px * 1)) / 2);
      :nth-child(2n + 2) {
        margin-right: 0;
      }
      :nth-child(2n + 1) {
        margin-right: 10px;
      }
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      width: 100%;
      margin-rigth: 0;
    }
  }
`
