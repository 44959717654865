import React from "react"
import Select from "react-select"
import styled from "styled-components"

import FlexSection from "../reusable-components/layout/flex-section"

import { getPricePerSquareFormatted } from "../../lib/utils"

const ProductVarationSelect = ({
  setSelectedVariation,
  selectedVariation,
  variations,
}) => {
  // Create array structure for the dropdown
  const options = variations.map((vr) => {
    const variationSquareM =
      vr?.acf_product_tile_add_ons?.squareMetresOfTilesPerBox
    const price = getPricePerSquareFormatted(vr.price, variationSquareM)
    let dropdownText = vr.name
    for (let index = 0; index < vr?.attributes?.nodes?.length; index++) {
      const attribute = vr?.attributes?.nodes?.[index]
      // If the only attribute is size then show only the size as the dropdown
      // label to keep the dropdown labels concise
      if (
        vr?.attributes?.nodes?.length === 1 &&
        attribute?.name?.includes?.("size") &&
        attribute?.value
      ) {
        dropdownText = attribute?.value
        break
      }
    }
    return {
      value: vr.variationId,
      label: (
        <Option>
          <div className="size">{dropdownText}</div>
          <div className="price">
            {`${price} `}
            {!variationSquareM ? null : (
              <span className="sale-price">
                / m<sup>2</sup>
              </span>
            )}
          </div>
        </Option>
      ),
    }
  })

  // Dropdown change handler function. We want to set the variation, not only the ID
  const handleChange = (props) => {
    setSelectedVariation(variations.find((vr) => vr.variationId == props.value))
  }

  // Find the one in options struct that matches selected Variation
  const selectedOption = options.find(
    (opt) => opt?.value == selectedVariation?.variationId
  )

  return (
    <FlexSectionStyling direction="row" margin="0 0 1rem">
      <Select
        styles={{
          container: (styles) => ({
            ...styles,
            width: "100%",
          }),
          control: (styles) => ({
            ...styles,
            border: "1px solid #003a8d",
            // padding: "10px",
            borderRadius: "2px",
            // height: 53px;
            cursor: "pointer",
            height: "100%",
          }),
          option: (styles) => ({
            ...styles,
            cursor: "pointer",
          }),
          singleValue: (styles) => ({
            ...styles,
            width: "100%",
            padding: "0 10px 0 0",
          }),
          indicatorsContainer: (styles) => ({
            ...styles,
            svg: {
              color: "#333333",
            },
          }),
        }}
        value={selectedOption}
        onChange={handleChange}
        options={options}
      />
    </FlexSectionStyling>
  )
}

export default ProductVarationSelect

// ===============
//     STYLES
// ===============

const FlexSectionStyling = styled(FlexSection)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    flex-direction: row;
    flex-wrap: wrap;
    > a {
      width: fit-content !important;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin-bottom: 2rem;
    button {
      width: fit-content;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    flex-direction: column;
    .wishlist {
      padding-left: 0;
    }
  }
`

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  .price {
    font-weight: 500;
  }
`
