import React from "react"
import styled from "styled-components"

import { getProperty } from "../../lib/utils"

const SpecsTabContent = ({
  product,
  colour,
  finish,
  material,
  specsACF,
  pdfURL,
}) => {
  const weight = getProperty(product, ["weight"], "")
  const length = getProperty(product, ["length"], "")
  const width = getProperty(product, ["width"], "")
  const height = getProperty(product, ["height"], "")

  const dimensions = []
  length && dimensions.push(`${length} cm (L)`)
  width && dimensions.push(`${width} cm (W)`)
  height && dimensions.push(`${height} cm (H)`)
  return (
    <TableStyling>
      <tbody>
        <tr>
          <td>Weight</td>
          <td>{weight != "" ? `${weight} kg` : "N/A"}</td>
        </tr>
        <tr>
          <td>Dimensions</td>
          <td>{!dimensions.length ? "N/A" : dimensions.join(" x ")}</td>
        </tr>
        <tr>
          <td>Colour</td>
          <td className="colour-value">{colour}</td>
        </tr>
        <tr>
          <td>Finish</td>
          <td className="finish-value">{finish}</td>
        </tr>
        <tr>
          <td>Material</td>
          <td className="material-value">{material}</td>
        </tr>
        {pdfURL ? (
          <tr>
            <td>Specification Link</td>
            <td>
              <a target="_blank" rel="noreferrer" href={pdfURL}>
                {pdfURL.substring(pdfURL.lastIndexOf("/") + 1)}
              </a>
            </td>
          </tr>
        ) : null}
        {!specsACF
          ? null
          : specsACF.map((spec, spec_i) => {
              const specTitle = getProperty(spec, ["specTitle"], "")
              const specDescription = getProperty(spec, ["specDescription"], "")
              return (
                <tr key={spec_i}>
                  <td>{specTitle}</td>
                  <td>{specDescription}</td>
                </tr>
              )
            })}
      </tbody>
    </TableStyling>
  )
}

export default SpecsTabContent

// ===============
//     STYLES
// ===============
const TableStyling = styled.table`
  margin: 0 1.875rem;
  width: calc(100% - 3.75rem);
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin: 0;
    width: 100%;
  }
  border: none;
  tr:nth-child(even) {
    background: ${({ theme }) => theme.colors.lightgrey2};
  }
  td {
    border: 1px solid ${({ theme }) => theme.colors.lightgrey3};
    font-weight: normal;
    text-align: left;
    padding: 0.75rem 1rem;
  }
  tr td:first-child {
    width: 200px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      width: 120px;
    }
  }
  .colour-value {
    text-transform: capitalize;
  }
`
