import React from "react"
import ProductStarRating from "../products/product-star-rating"

const ProductRatingRow = ({ rating }) => {
  return (
    <>
      {rating < 0.5 ? null : (
        <ProductStarRating rating={rating} display="block" margin="0" />
      )}
    </>
  )
}

export default ProductRatingRow
