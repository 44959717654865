import React, { useLayoutEffect, useEffect, useState } from "react"
import SEO from "../../components/theme/header/seo"
import { requestProductStatus } from "../../api/product"
import styled from "styled-components"
import { slugify } from "../../lib/js-utils"
import FlexSection from "../../components/reusable-components/layout/flex-section"
import DividerLine from "../../components/reusable-components/divider-line"
import ProductImageSection from "../../components/product-page/product-image-section"
import ProductBrandRow from "../../components/product-page/product-brand-row"
import ProductRatingRow from "../../components/product-page/product-rating-row"
import ProductPriceRow from "../../components/product-page/product-price-row"
import ProductVarationSelect from "../../components/product-page/product-variation-select"
import ProductQuantityRow from "../../components/product-page/product-quantity-row"
import ShareButtons from "../../components/reusable-components/share-buttons"
import ProductTabsSection from "../../components/product-page/product-tabs-section"

import { analyticsViewItem } from "../../services/google-analytics"
import { pixelSelect } from "../../services/facebook-pixel"

import { getProperty, getQueryVariable } from "../../lib/utils"

const ProductInfo = ({ pageContext, data, location }) => {
  // Figure out whether thiis is a variable or a simple product
  let mainProduct = getProperty(data, ["variableProduct"], "")
  if (!mainProduct) {
    mainProduct = getProperty(data, ["simpleProduct"], "")
  }

  // Get variations, if there are any
  const variations = getProperty(mainProduct, ["variations", "nodes"], [])

  const [productStock, setProductStock] = useState({})
  const [productReviews, setProductReviews] = useState([])
  const [selectedVariation, setSelectedVariation] = useState(() => {
    const defaultVariation = getQueryVariable("select")
    if (defaultVariation) {
      const matchingVariation = variations.find((vr) =>
        vr?.attributes?.nodes?.find((att) => att?.value == defaultVariation)
      )
      if (matchingVariation) {
        return matchingVariation
      }
    }
    return getProperty(variations, [0], null)
  })

  const brandsCategorySlug = getProperty(
    pageContext,
    ["brandsCategorySlug"],
    ""
  )

  const title = getProperty(mainProduct, ["name"], "")
  const description =
    getProperty(selectedVariation, ["description"], "") ||
    getProperty(mainProduct, ["description"], "")
  const categoryData = getProperty(
    mainProduct,
    ["productCategories", "nodes"],
    []
  )
  const brandsCategoryId = getProperty(
    data,
    ["brandsCategory", "databaseId"],
    0
  )
  const brands = categoryData.filter(
    (node) => node.parentDatabaseId === brandsCategoryId
  )

  const paColoursData = getProperty(mainProduct, ["allPaColour", "nodes"], "")
  const paFinishesData = getProperty(mainProduct, ["allPaFinish", "nodes"], "")
  const paMaterialsData = getProperty(
    mainProduct,
    ["allPaMaterial", "nodes"],
    ""
  )

  const yoast = getProperty(data, ["seaProduct", "seo"], {})

  const colourExists = paColoursData.length > 0
  const productColourArray = []
  let productColours = "N/A"
  if (colourExists) {
    paColoursData.forEach((colour) => {
      const productColour = getProperty(colour, ["name"], "")
      productColourArray.push(productColour)
    })
    productColours = productColourArray.join(", ")
  }

  const finishExists = paFinishesData.length > 0
  const productFinishArray = []
  let productFinishes = "N/A"
  if (finishExists) {
    paFinishesData.forEach((finish) => {
      const productFinish = getProperty(finish, ["name"], "")
      productFinishArray.push(productFinish)
    })
    productFinishes = productFinishArray.join(", ")
  }

  const materialExists = paMaterialsData.length > 0
  const productMaterialArray = []
  let productMaterials = "N/A"
  if (materialExists) {
    paMaterialsData.forEach((material) => {
      const productMaterial = getProperty(material, ["name"], "")
      productMaterialArray.push(productMaterial)
    })
    productMaterials = productMaterialArray.join(", ")
  }

  let siteUrlSharing = ""
  if (typeof window != "undefined" && window.location) {
    siteUrlSharing = window.location.href
  }

  // Get fallback stock and average review rating from GraphQL structure
  let stockStatus =
    getProperty(selectedVariation, ["stockStatus"], "") ||
    getProperty(mainProduct, ["stockStatus"], "")
  let rating =
    getProperty(selectedVariation, ["averageRating"], 0) ||
    getProperty(mainProduct, ["averageRating"], 0)
  let productId = getProperty(pageContext, ["productId"], "")
  let variationId = getProperty(selectedVariation, ["variationId"], "")

  // Overwrite average review rating value if it was fetched from backend
  // (parent product takes precedent)
  if (productStock && productStock[productId]?.review_average_rating) {
    rating =
      (productStock && productStock[productId]?.review_average_rating) || 0
  } else if (productStock && productStock[variationId]?.review_average_rating) {
    rating =
      (productStock && productStock[variationId]?.review_average_rating) || 0
  }
  // Overwrite stock value if it was fetched from backend
  // (variation takes precedent)
  if (productStock && productStock[variationId]?.stock_status) {
    stockStatus = productStock[variationId]?.stock_status
  } else if (productStock && productStock[productId]?.stock_status) {
    stockStatus = productStock[productId]?.stock_status
  }
  // standardise the stock status because graphql and backend have different formatting
  stockStatus = slugify(stockStatus).replace(/-/g, "")

  // extract tiles per box. Use variant -> fallback to parent -> fallback to null
  const selectedSquareMPerBox =
    selectedVariation?.acf_product_tile_add_ons?.squareMetresOfTilesPerBox ||
    mainProduct?.acf_product_tile_add_ons?.squareMetresOfTilesPerBox ||
    null

  // #### Hooks ####
  // On load, trigger analytics for view product
  useEffect(() => {
    const currentProduct = selectedVariation || mainProduct
    if (currentProduct) {
      const preparedData = {
        ...currentProduct,
        categories: categoryData,
      }
      analyticsViewItem(preparedData)
      pixelSelect(preparedData)
    }
  }, [selectedVariation, mainProduct])
  // On load, fetch product stock, rating and reviews
  useLayoutEffect(() => {
    const productId = getProperty(pageContext, ["productId"], "")
    if (productId) {
      requestProductStatus(productId).then((resp) => {
        if (resp?.stock_levels) {
          setProductStock(resp.stock_levels)
        }
        if (resp && resp?.reviews?.reviews) {
          setProductReviews(resp.reviews.reviews)
        }
      })
    }
  }, [])

  return (
    <>
      <SEO
        title={getProperty(data, ["seaProduct", "seo", "title"], "")}
        location={location}
        yoast={yoast}
      />
      <StyledFlexSection direction="row">
        <ProductImageSection
          selectedVariation={selectedVariation}
          product={mainProduct}
          productTitle={title}
        />
        <InfoSectionStyling>
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          <ProductBrandRow
            brands={brands}
            brandsCategorySlug={brandsCategorySlug}
          />
          <ProductRatingRow rating={rating} />
          <ProductPriceRow
            product={selectedVariation || mainProduct}
            selectedSquareMPerBox={selectedSquareMPerBox}
          />
          <SpanHeadingStyling>
            Availability:
            {stockStatus == "instock" && (
              <SpanInStockStyling>In Stock</SpanInStockStyling>
            )}
            {stockStatus == "outofstock" && (
              <SpanOutOfStockStyling>Out of Stock</SpanOutOfStockStyling>
            )}
            {stockStatus == "onbackorder" && (
              <SpanOutOfStockStyling>On Backorder</SpanOutOfStockStyling>
            )}
          </SpanHeadingStyling>
          <SpanHeadingStyling>
            Shipping Estimate:
            <SpanShippingStyling>
              2-3 Working Days (In economic centers)
            </SpanShippingStyling>
          </SpanHeadingStyling>
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className="description"
          />
          <DividerLine margin="1.875rem 0" />
          {selectedVariation && (
            <ProductVarationSelect
              setSelectedVariation={setSelectedVariation}
              selectedVariation={selectedVariation}
              variations={variations}
            />
          )}
          <ProductQuantityRow
            productId={
              getProperty(selectedVariation, ["variationId"]) ||
              getProperty(pageContext, ["productId"])
            }
            selectedSquareMPerBox={selectedSquareMPerBox}
          />
          <SpanHeadingStyling>
            Colours:
            <SpanColourStyling>{productColours}</SpanColourStyling>
          </SpanHeadingStyling>
          <ShareButtons sharingUrl={siteUrlSharing} type={"Product"} />
        </InfoSectionStyling>
      </StyledFlexSection>
      <ProductTabsSection
        product={selectedVariation || mainProduct}
        productId={getProperty(pageContext, ["productId"], "")}
        colour={productColours}
        finish={productFinishes}
        material={productMaterials}
        productReviews={productReviews}
      />
    </>
  )
}

export default ProductInfo

// ===============
//     STYLES
// ===============
const StyledFlexSection = styled(FlexSection)`
  margin-top: 20px;
`
const InfoSectionStyling = styled.div`
  margin-left: 3.125rem;
  width: 50%;
  h1 {
    font-size: 1.75rem;
    margin-bottom: 0;
  }
  p {
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      min-height: auto;
    }
  }
  .product-rating {
    margin-bottom: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;
    margin-left: 0;
  }
`
const SpanHeadingStyling = styled.span`
  margin-top: 0.3rem;
  display: block;
`
const SpanInStockStyling = styled.span`
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.success.light};
`
const SpanOutOfStockStyling = styled.span`
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.danger.light};
`
const SpanShippingStyling = styled.span`
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
`
const SpanColourStyling = styled.span`
  color: ${({ theme }) => theme.colors.grey};
  text-transform: capitalize;
  margin-left: 0.5rem;
  font-size: 1rem;
`
