import React from "react"
import styled from "styled-components"

import FlexSection from "../reusable-components/layout/flex-section"
import DividerLine from "../reusable-components/divider-line"
import ProductStarRating from "../products/product-star-rating"
import ReviewForm from "./review-form"

import { stripExtraDateInfo } from "../../lib/utils"

const ProductReviewsTabContent = ({
  location = {},
  productId = "",
  productReviews = [],
}) => {
  return (
    <Container>
      {productReviews.map((singleReview, i) => {
        return (
          <div key={`${i}-review`}>
            <ReviewSection>
              <FlexSectionStyling
                direction="row"
                justify="space-between"
                margin="0"
              >
                <h6>{singleReview.author}</h6>
                <p>{stripExtraDateInfo(singleReview.date)}</p>
              </FlexSectionStyling>
              <ProductStarRating rating={singleReview.rating} />
              <p>{singleReview.content}</p>
            </ReviewSection>
            <DividerLine margin="3.125rem 0" />
          </div>
        )
      })}
      {(!productReviews || productReviews.length == 0) && (
        <>
          <p>Be the first to review this product!</p>
          <DividerLine margin="3.125rem 0" />
        </>
      )}
      <LeaveReviewSection>
        <ReviewForm
          productId={productId}
          location={location}
          formName="review"
        />
      </LeaveReviewSection>
    </Container>
  )
}

export default ProductReviewsTabContent

// ===============
//     STYLES
// ===============
const Container = styled.div`
  margin: 0 1.875rem;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin: 0;
  }
`
const ReviewSection = styled.div`
  margin: 0 1.875rem;
  h6 {
    font-weight: bold;
    line-height: 1.875;
  }
  > div p {
    margin-top: 0;
    color: ${({ theme }) => theme.colors.blue};
  }
  > p {
    margin-top: 1.25rem;
  }
`
const FlexSectionStyling = styled(FlexSection)`
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
    margin-bottom: 0.625rem;
  }
`
const LeaveReviewSection = styled.div`
  h3 {
    font-weight: bold;
  }
  p {
    margin-top: 0;
  }
`
