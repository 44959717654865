import React from "react"

const StarHalfFill = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="36.092px"
    height="36.092px"
    viewBox="0 0 36.092 36.092"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill="#FFD800"
        d="M36.043,13.909
      c-0.123-0.377-0.456-0.646-0.852-0.688
      l-11.549-1.171
      L18.96,1.431
      c-0.159-0.363-0.518-0.596-0.914-0.596
      s-0.755,0.234-0.915,0.597
      l-4.683,10.62
      L0.9,13.222
      c-0.395,0.04-0.728,0.309-0.851,0.688
      c-0.122,0.377-0.011,0.791,0.285,1.055
      l8.651,7.734
      L6.532,34.044
      c-0.084,0.387,0.07,0.789,0.391,1.021
      c0.321,0.231,0.747,0.254,1.091,0.055
      l10.031-5.838
      l10.029,5.838
      c0.156,0.092,0.33,0.137,0.505,0.137
      c0.207,0,0.411-0.064,0.588-0.191
      c0.319-0.231,0.474-0.633,0.39-1.021
      L27.104,22.7
      l8.651-7.735
      C36.054,14.698,36.165,14.286,36.043,13.909z M25.339,21.594
      c-0.271,0.24-0.39,0.604-0.312,0.957
      l2.099,9.699
      l-8.574-4.99
      c-0.001,0-0.002,0-0.003-0.002
      V5.447
      l3.503,7.943
      c0.146,0.33,0.456,0.555,0.813,0.592
      l9.869,1.001
      L25.339,21.594z"
      />
    </g>
  </svg>
)

export default StarHalfFill
