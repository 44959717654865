const timeStampedLog = (message) => {
  console.log(
    `${
      new Date().toISOString().match(/(\d\d:\d\d:\d\d\.\d\d\d\w)/)[0]
    } > ${message}`
  )
}

const getProperty = (inputObject, propertyPath, defaultReturn = null) => {
  try {
    let returnVal = inputObject
    for (let i = 0; i < propertyPath.length; ++i) {
      const newKey = propertyPath[i]
      if (!Object.prototype.hasOwnProperty.call(returnVal, newKey)) {
        throw new TypeError(`Cannot read property '${newKey}'`)
      }
      returnVal = returnVal[newKey]
    }
    return returnVal
  } catch (error) {
    // if (process.env.GATSBY_ACTIVE_ENV == "development") {
    //   console.warn(`getProperty: ${error}`)
    // }
  }
  return defaultReturn
}

//source: https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
const slugify = (string) => {
  const specialCharacters =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;"
  const urlSafeCharacters =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------"
  const characterRegex = new RegExp(specialCharacters.split("").join("|"), "g")

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(characterRegex, (character) =>
      urlSafeCharacters.charAt(specialCharacters.indexOf(character))
    ) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}

const titleCase = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )

module.exports = { timeStampedLog, slugify, titleCase, getProperty }
