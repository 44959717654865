import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/theme/layout"
import ProductRelatedSection from "../../components/product-page/product-related-section"

import { getProperty } from "../../lib/utils"
import ProductInfo from "../../components/product-page/product-info"
import { useEffect } from "react"

const ProductPage = ({ pageContext, data, location }) => {
  // The product page body was move to ProductInfo as the states
  // caused the relatedProducts query to lose its data

  const [relatedProducts, setRelatedProducts] = useState([])

  useEffect(() => {
    setRelatedProducts(getProperty(data, ["allWpSimpleProduct", "nodes"], []))
  }, [])

  console.log(data)

  return (
    <Layout pathContext={pageContext} location={location}>
      <ProductInfo
        pageContext={pageContext}
        data={data}
        location={location}
      ></ProductInfo>
      <ProductRelatedSection relatedProducts={relatedProducts} />
    </Layout>
  )
}

export default ProductPage

// ===============
//      QUERY
// ===============
export const productQueryData = graphql`
  query GET_PRODUCT_PAGE_QUERY($productId: Int!, $relatedProducts: [Int]) {
    simpleProduct: wpSimpleProduct(databaseId: { eq: $productId }) {
      name
      sku
      databaseId
      slug
      price
      regularPrice
      salePrice
      onSale
      stockStatus
      averageRating
      description
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      image {
        sourceUrl
        sizes
        srcSet
      }
      galleryImages {
        nodes {
          sourceUrl
          sizes
          srcSet
        }
      }
      allPaColour {
        nodes {
          name
        }
      }
      allPaFinish {
        nodes {
          name
        }
      }
      allPaMaterial {
        nodes {
          name
        }
      }
      weight
      length
      width
      height
      attributes {
        nodes {
          name
          options
        }
      }
      acf_product_information {
        productSpecifications {
          specTitle
          specDescription
        }
        productTechnicalDrawing {
          tdImage {
            sourceUrl
          }
          tdFile {
            localFile {
              publicURL
            }
          }
        }
      }
      acf_product_tile_add_ons {
        squareMetresOfTilesPerBox
      }
      productCategories {
        nodes {
          name
          parentDatabaseId
          slug
        }
      }
    }
    variableProduct: wpVariableProduct(databaseId: { eq: $productId }) {
      name
      sku
      slug
      price
      regularPrice
      salePrice
      onSale
      averageRating
      description
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      image {
        sourceUrl
        sizes
        srcSet
      }
      galleryImages {
        nodes {
          sourceUrl
          sizes
          srcSet
        }
      }
      allPaColour {
        nodes {
          name
        }
      }
      allPaFinish {
        nodes {
          name
        }
      }
      allPaMaterial {
        nodes {
          name
        }
      }
      weight
      length
      width
      height
      attributes {
        nodes {
          name
          options
        }
      }
      acf_product_information {
        productSpecifications {
          specTitle
          specDescription
        }
        productTechnicalDrawing {
          tdImage {
            sourceUrl
          }
          tdFile {
            localFile {
              publicURL
            }
          }
        }
      }
      acf_product_tile_add_ons {
        squareMetresOfTilesPerBox
      }
      productCategories {
        nodes {
          name
          parentDatabaseId
          slug
        }
      }
      variations {
        nodes {
          sku
          name
          price
          onSale
          description
          regularPrice
          variationId: databaseId
          stockStatus
          weight
          width
          length
          image {
            sourceUrl
          }
          attributes {
            nodes {
              name
              value
            }
          }
          acf_product_tile_add_ons {
            squareMetresOfTilesPerBox
          }
        }
      }
    }
    brandsCategory: wpProductCategory(slug: { eq: "brands" }) {
      databaseId
    }
    allWpSimpleProduct(filter: { databaseId: { in: $relatedProducts } }) {
      nodes {
        name
        productId: databaseId
        slug
        price
        regularPrice
        onSale
        shortDescription
        averageRating
        image {
          sourceUrl
        }
        acf_product_information {
          productHoverImage {
            sourceUrl
          }
        }
        acf_product_tile_add_ons {
          squareMetresOfTilesPerBox
        }
      }
    }
    seaProduct(database_id: { eq: $productId }) {
      seo {
        title
        description
        meta_description
        og_description
        og_image
        og_title
        og_type
        site_name
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
  }
`
