import React, { useContext, useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import LazyLoadWrapper from "../../components/reusable-components/lazy-load-wrapper"

import LoadingSubmitButton from "../reusable-components/buttons/loading-submit-button"
import LinkWrapper from "../reusable-components/link-wrapper"
import ProductStarRating from "../products/product-star-rating"
import { analyticsSelect } from "../../services/google-analytics"

import CartContext from "../../context/cart/cart-context"
import { getProperty, getPricePerSquareFormatted } from "../../lib/utils"

import CloudflareImage from "../reusable-components/cloudflare-image"

const ProductCard = ({ product, width = "230px", className = "" }) => {
  const placeholderData = useStaticQuery(PRODUCT_CARD_PLACEHOLDER_IMAGE_QUERY)
  const [addToCartLoading, setAddToCartLoading] = useState(false)
  const [apiError, setApiError] = useState("")

  const { addToCart, openCartDrawer } = useContext(CartContext)

  // Product general info
  const placeholderImgUrl =
    placeholderData?.wpPage?.acf_home_page?.placeholderImage?.sourceUrl || null
  const name = getProperty(product, ["name"], "")
  const variations = getProperty(product, ["variations", "nodes"], [])
  const variationAttribute = getProperty(
    product,
    ["attributes", "nodes", 0, "value"],
    ""
  )
  const product_id =
    getProperty(product, ["variationId"], "") ||
    getProperty(product, ["productId"], "")
  const slug = getProperty(product, ["slug"], "")
  const rating = getProperty(product, ["averageRating"], "")
  let link = `/product/${slug}/`
  if (variationAttribute) {
    link = `${link}?select=${encodeURIComponent(variationAttribute)}`
  }

  // Product price info
  const squareM =
    product?.acf_product_tile_add_ons?.squareMetresOfTilesPerBox || null
  const price = getPricePerSquareFormatted(product?.price, squareM) || ""
  const regularPrice =
    getPricePerSquareFormatted(product?.regularPrice, squareM) || ""
  const onSale = getProperty(product, ["onSale"], "")

  // Product image info, with fallback to variation image
  const productHoverImage =
    getProperty(product, ["productHoverImage"], null) ||
    getProperty(
      product,
      ["acf_product_information", "productHoverImage", "sourceUrl"],
      null
    )
  let productImage = getProperty(product, ["image", "sourceUrl"])
  if (variations.length > 0 && !productImage) {
    productImage = getProperty(
      variations,
      [0, "image", "sourceUrl"],
      placeholderImgUrl
    )
  }

  // Handle the add-to-cart button
  const handleAddToCart = () => {
    setAddToCartLoading(true)
    addToCart({ id: product_id, quantity: 1 })
      .then(() => {
        setApiError("")
        setAddToCartLoading(false)
        openCartDrawer(true)
      })
      .catch(async (error) => {
        let errorString = "Failed to add product to cart"
        if (error.message) {
          errorString = error.message
          console.log("Error Response: ", errorString)
        } else {
          console.log("Error Response: ", error)
        }
        setApiError(errorString)
        setAddToCartLoading(false)
      })
  }
  return (
    <LazyLoadWrapper placeholderHeight="388px">
      <ProductCardStyling
        className={className}
        width={width}
        onClick={() => {
          analyticsSelect(product)
        }}
      >
        {onSale && <DiscountStyling>ON SALE</DiscountStyling>}
        <LinkWrapper to={link}>
          <ProductImgStyling className={"full-width-inner-section"}>
            <CloudflareImage
              src={productImage}
              className={productHoverImage ? "main-image" : ""}
              alt={name}
            />
            {!productHoverImage ? null : (
              <CloudflareImage
                src={productHoverImage}
                className="hover-image"
                alt={name}
              />
            )}
          </ProductImgStyling>
        </LinkWrapper>
        <div className="product-card-bottom-wrapper">
          <ProductDescriptionContainer className="data-hover-effect">
            {!apiError ? null : (
              <ErrorMessage dangerouslySetInnerHTML={{ __html: apiError }} />
            )}
            <LinkWrapper to={link}>
              <ProductDescriptionStyling
                dangerouslySetInnerHTML={{ __html: name }}
                className={onSale ? "on-sale" : ""}
              />
            </LinkWrapper>
            {/* {shortDescription.length < 1 ? (
            <ProductShortDiscriptionStyling />
          ) : (
            <ProductShortDiscriptionStyling
              dangerouslySetInnerHTML={{
                __html: shortDescription,
              }}
            />
          )} */}
            {!onSale ? (
              <PricingStyling>
                {regularPrice}
                {!squareM ? null : (
                  <>
                    {" / m"}
                    <sup>2</sup>
                  </>
                )}
              </PricingStyling>
            ) : (
              <PricingStyling>
                <span>{regularPrice}</span>
                <span>
                  {price}
                  {!squareM ? null : (
                    <>
                      {" / m"}
                      <sup>2</sup>
                    </>
                  )}
                </span>
              </PricingStyling>
            )}
            {rating < 0.5 ? null : <ProductStarRating rating={rating} />}
          </ProductDescriptionContainer>
          <LoadingSubmitButton
            text="Add to cart"
            loading={addToCartLoading}
            loaderColour="white"
            loaderHeight="53px"
            callbackFunction={handleAddToCart}
            padding="0"
          />
        </div>
      </ProductCardStyling>
    </LazyLoadWrapper>
  )
}

export default ProductCard

// ===============
//     STYLES
// ===============
const ProductCardStyling = styled.div`
  position: relative;
  width: ${({ width }) => width};
  h5 {
    font-size: 0.88rem;
    line-height: 1.3rem;
    text-transform: none;
    min-height: 40px;
  }
  h6 {
    color: ${({ theme }) => theme.colors.lightgrey5};
    font-size: 0.75rem;
    line-height: 1rem;
  }
  button {
    width: 100%;
    max-height: 53px;
    margin-top: calc(138px - 53px);
    border: ${({ theme }) => theme.colors.lightgrey3};
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.lightgrey3};
    color: ${({ theme }) => theme.colors.black1};
    &:hover {
      border: ${({ theme }) => theme.colors.grey};
      background-color: ${({ theme }) => theme.colors.grey};
      color: white;
    }
  }
  .data-hover-effect {
    position: absolute;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
    min-height: 138px;
    text-align: center;
    padding: 15px;
    background: white;
  }
  .hover-image {
    display: none;
  }
  .product-card-bottom-wrapper:hover > .data-hover-effect {
    bottom: 53px;
    transition: all 0.3s ease-in-out;
  }
  .full-width-inner-section:hover > .hover-image {
    display: block;
  }
  .full-width-inner-section:hover > .main-image {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    &.fullwidthcards {
      width: 250px;
      margin: 0 auto;
    }
    .product-card-bottom-wrapper:hover > .data-hover-effect {
      bottom: 0;
    }
    .full-width-inner-section:hover > .hover-image {
      display: none;
    }
    .full-width-inner-section:hover > .main-image {
      display: block;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    &.fullwidthcards {
      width: 100%;
    }
  }
`
const DiscountStyling = styled.div`
  position: absolute;
  right: 0;
  z-index: 50;
  vertical-align: middle;
  padding: 0 5px;
  height: 30px;
  margin-right: 0 !important;
  border-radius: 1px;
  background-color: #e5322c;
  color: white;
  font-weight: 300;
  text-align: center;
  line-height: 30px;
`
const ProductImgStyling = styled.div`
  height: 250px;
  img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    img {
      width: 100%;
    }
    &.full-width-inner-section {
      margin-bottom: 0rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    height: 100%;
  }
`
const ErrorMessage = styled.div`
  color: red;
`
const ProductShortDiscriptionStyling = styled.div`
  overflow: hidden;
  max-height: 1rem;
  min-height: 1rem;
  color: ${({ theme }) => theme.colors.lightgrey5};
  font-size: 0.75rem;
  line-height: 1rem;
  p {
    margin: 0;
    color: ${({ theme }) => theme.colors.lightgrey5};
    font-size: 0.75rem;
    line-height: 1rem;
  }
`
const PricingStyling = styled.div`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.black1};
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.3rem;
  & span:first-child {
    padding-right: 10px;
  }
  & span:not(:last-child) {
    padding-right: 10px;
    color: ${({ theme }) => theme.colors.lightgrey5};
    text-decoration: line-through;

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      padding-right: 0;
      display: block;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    > span {
      display: block;
    }
  }
`

const ProductDescriptionStyling = styled.h5`
  color: rgb(74, 74, 76);
  font-weight: 400;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &.on-sale {
    -webkit-line-clamp: 3;
  }
`

const ProductDescriptionContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`

// ===============
//      QUERY
// ===============
const PRODUCT_CARD_PLACEHOLDER_IMAGE_QUERY = graphql`
  {
    wpPage(slug: { eq: "home" }) {
      acf_home_page {
        placeholderImage {
          sourceUrl
        }
      }
    }
  }
`
