import React from "react"

const QuantityArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.978"
    height="5.196"
    viewBox="0 0 8.978 5.196"
  >
    <path
      id="quantity-arrow"
      d="M1096.241,4342.554l4.135-4.135,4.135,4.135"
      transform="translate(-1095.887 -4337.711)"
      fill="none"
      stroke="#003a8d"
      strokeWidth="1"
    />
  </svg>
)

export default QuantityArrow
