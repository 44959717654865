import { kyWrapped } from "../lib/utils"

// Endpoint to post a product review. Can be anonymous or while logged in
export const postReview = (review, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/review/${review.id}`
  let headers = {}
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, {
    headers,
    json: review,
    method: "post",
    logout,
  })
}
