import { kyWrapped } from "../lib/utils"

// Endpoint to request product stock and reviews
export const requestProductStatus = (productId) => {
  productId = parseInt(productId)
  if (Number.isInteger(productId)) {
    const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/product-status/${productId}`
    return kyWrapped(url, {
      method: "get",
    })
  }
  console.warn("Tried to request stock for invalid product ID", productId)
  return Promise.resolve(false)
}
