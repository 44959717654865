import React from "react"
import styled from "styled-components"

import {
  getProperty,
  formatPrice,
  getPricePerSquareNumber,
} from "../../lib/utils"

const ProductPriceRow = ({ product, selectedSquareMPerBox }) => {
  const price = getProperty(product, ["price"], "")
  const regularPrice = getProperty(product, ["regularPrice"], "")
  const priceNumber = getPricePerSquareNumber(price, selectedSquareMPerBox)
  const regularPriceNumber = getPricePerSquareNumber(
    regularPrice,
    selectedSquareMPerBox
  )
  const formattedPrice = formatPrice({ price: priceNumber })
  const formattedRegularPrice = formatPrice({ price: regularPriceNumber })
  const onSale = getProperty(product, ["onSale"], "")
  return (
    <PriceRowContainer>
      {!onSale ? null : (
        <span
          dangerouslySetInnerHTML={{ __html: formattedRegularPrice }}
          className="regular-price" id="reg_price"
        />
      )}
      <span
        dangerouslySetInnerHTML={{ __html: formattedPrice }}
        className="sale-price"
      />
      {!selectedSquareMPerBox ? null : (
        <span className="sale-price" id="reg_price">
          / m<sup>2</sup>
        </span>
      )}
      <span className="vat-text">(incl. VAT)</span>
    </PriceRowContainer>
  )
}

export default ProductPriceRow

// ===============
//     STYLES
// ===============
const PriceRowContainer = styled.div`
  span {
    font-size: 1.313rem;
  }
  .regular-price {
    color: ${({ theme }) => theme.colors.grey};
    text-decoration: line-through;
    margin-right: 1rem;
  }
  .sale-price {
    font-weight: bold;
    margin-right: 0.5rem;
  }
  .vat-text {
    font-size: 0.75rem;
  }
`
