import React, { useEffect, useState, memo, useRef } from "react"
import styled from "styled-components"

import QuantityArrowIcon from "../../images/icons/quantity-arrow"

import { roundTo2 } from "../../lib/utils"

// ====================================================
// Counter
//
// This component displays a counter that deals
// in numbers that can have decimals
// =====================================================
export const Counter = memo(
  ({
    value = 0,
    onChange = () => {},
    onFocusChange = () => {},
    focus = false,
    min = 0,
    max = 999,
    disabled = false,
    isReadOnly = false,
    stepSize = 0.01,
  }) => {
    const isIntCounter = stepSize == 1
    const [amount, setAmount] = useState(value)
    const valueComponentRef = useRef(null)

    const inc = () => inc_or_dec(stepSize)
    const dec = () => inc_or_dec(-stepSize)

    const inc_or_dec = (change_val) => {
      let new_amount = roundTo2(parseFloat(amount))
      if (typeof new_amount !== "number" || Number.isNaN(new_amount)) {
        new_amount = min
      } else {
        new_amount += change_val
        if (new_amount < min) {
          new_amount = min
        } else if (new_amount > max) {
          new_amount = max
        }
      }
      new_amount = roundTo2(new_amount)
      update(new_amount)
    }

    const update = (amount) => {
      setAmount(amount)
      onChange(amount)
    }

    // Update the internal "amount" when "value" prop changes
    useEffect(() => {
      if (value < min) {
        value = min
      }
      if (value > max) {
        value = max
      }
      if (amount !== value) {
        setAmount(value)
      }
    }, [value])

    // Update the internal "value" prop when "amount" state changes
    useEffect(() => {
      if (amount != value) {
        value = amount
      }
    }, [amount])

    // focus on value input right after render
    useEffect(() => {
      if (focus) {
        valueComponentRef.current.focus()
      }
    }, [focus])

    const handleChange = (changeObj) => {
      let newValue = changeObj?.target?.value
      newValue = newValue.replace(/^[0]+/g, "")
      newValue = newValue.replace(/[^0-9.]+/g, "")
      if (isIntCounter) {
        newValue = newValue.replace(/\./g, "")
      }
      const floatValue = parseFloat(newValue)

      if (isNaN(floatValue)) {
        // match deleteContentForward for the delete key
        // match deleteContentBackward for the backspace key
        const nativeEvent = changeObj?.nativeEvent
        if (nativeEvent?.inputType?.startsWith?.("deleteContent")) {
          setAmount("")
          return
        }
        newValue = min
      }
      if (floatValue < min) {
        newValue = min
      }
      if (floatValue > max) {
        newValue = max
      }
      update(newValue)
      return
    }

    const handleBlur = () => {
      const floatAmount = roundTo2(parseFloat(amount))
      if (Number.isNaN(floatAmount)) {
        setAmount(min)
      }
      onFocusChange(false)
    }

    const handleFocus = () => {
      onFocusChange(true)
    }

    return (
      <CounterStyling>
        <StyledInput
          ref={valueComponentRef}
          isReadOnly={isReadOnly}
          readonly={isReadOnly}
          disabled={disabled}
          type="text"
          value={amount}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
        <ArrowButtonsStyling>
          <StyledButton disabled={disabled || amount >= max} onClick={inc}>
            <QuantityArrowIcon />
          </StyledButton>
          <StyledButton
            disabled={disabled || amount <= min}
            onClick={dec}
            className="down-arrow"
          >
            <QuantityArrowIcon />
          </StyledButton>
        </ArrowButtonsStyling>
      </CounterStyling>
    )
  }
)

export default Counter

// ===============
//     STYLES
// ===============
const CounterStyling = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-radius: 2px;
  overflow: hidden;
  margin: 0 20px;
  height: 53px;
  width: 90px;
  display: grid;
  grid-template-columns: 60px 30px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-bottom: 5px;
    margin-right: 0;
  }
`
const ArrowButtonsStyling = styled.div`
  width: 30px;
  .down-arrow {
    transform: scaleY(-1);
  }
  button:hover {
    svg path {
      stroke: white;
    }
  }
`
const StyledButton = styled.button`
  display: flex;
  width: 30px;
  height: 26.5px;
  margin: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: white;
  border: none;
  padding: 0;
  border-radius: 0;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 30px !important;
  }
`

const StyledInput = styled.input`
  ${({ isReadOnly }) => isReadOnly && "pointer-events: none;"};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 60px;
  height: 53px;
  text-align: center;
  border: none;
`
