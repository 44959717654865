import React from "react"
import styled from "styled-components"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"
import CloudflareImage from "./cloudflare-image"

const MagnifyImage = ({ featuredImage }) => {
  return (
    <MagnifyImageWrapper
      MagnifyImageWrapper
      className="fluid__image-container testclass"
    >
      <Zoom>
        <CloudflareImage src={featuredImage.sourceUrl}></CloudflareImage>
      </Zoom>
    </MagnifyImageWrapper>
  )
}

export default MagnifyImage

const MagnifyImageWrapper = styled.div``
