import React from "react"
import styled from "styled-components"

import LinkWrapper from "../reusable-components/link-wrapper"
import { getProperty } from "../../lib/utils"

const ProductBrandRow = ({ brands, brandsCategorySlug }) => {
  return (
    <BrandSectionStyling>
      {brands.length == 0 ? null : (
        <>
          {brands.map((brand, brand_i) => {
            const brandName = getProperty(brand, ["name"], "")
            const brandSlug = getProperty(brand, ["slug"], "")
            return (
              <div key={brand_i} className="brand">
                <LinkWrapper
                  to={`/${brandsCategorySlug}/${
                    !brandSlug ? "" : `${brandSlug}/`
                  }`}
                  className="brand"
                >
                  <h6 dangerouslySetInnerHTML={{ __html: brandName }} />
                </LinkWrapper>
                {brand_i >= brands.length - 1 ? null : (
                  <h6 className="brand comma">, </h6>
                )}
              </div>
            )
          })}
        </>
      )}
    </BrandSectionStyling>
  )
}

export default ProductBrandRow

// ===============
//     STYLES
// ===============
const BrandSectionStyling = styled.div`
  h6 {
    color: ${({ theme }) => theme.colors.lightgrey5};
    text-transform: capitalize;
  }
  .brand {
    display: inline-block;
  }
  .comma {
    padding-right: 0.2rem;
  }
`
