import React from "react"
import styled from "styled-components"

import StarFullIcon from "../../images/icons/star-fill"
import StarHalfIcon from "../../images/icons/star-half-fill"
import StarEmptyIcon from "../../images/icons/star-no-fill"

const ProductStarRating = ({
  rating = 5,
  display = "inline-block",
  margin = "0",
  changeRating = () => {},
  clickable = false,
}) => {
  let starArray = []
  for (let i = 0; i < 5; i++) {
    rating = rating - 1
    if (rating >= 0) {
      starArray.push(StarFullIcon)
    }
    if (rating == -0.5) {
      starArray.push(StarHalfIcon)
    }
    if (rating < -0.5) {
      starArray.push(StarEmptyIcon)
    }
  }

  return (
    <StarRowStyling display={display} margin={margin} clickable={clickable}>
      {starArray.map((Star, i) => (
        <span key={i} onClick={() => changeRating(i + 1)}>
          <Star />
        </span>
      ))}
    </StarRowStyling>
  )
}

export default ProductStarRating

// ===============
//     STYLES
// ===============
const StarRowStyling = styled.div`
  display: ${({ display }) => display};
  margin: ${({ margin }) => margin};
  svg {
    ${({ clickable }) => clickable && "cursor: pointer"};
    margin-right: 3px;
    height: 11px;
    width: 12px;
  }
`
